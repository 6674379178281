var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-shop text-primary'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Activity Dashboard', path: '/activityDashboard' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Epochs Dashboard', path: '/epochsDashboard' }}})],1),(_vm.proposalList.length > 0)?_c('sidebar-item',{attrs:{"link":{
          name: 'Providers',
          icon: 'ni ni-ui-04 text-info'
        }}},_vm._l((_vm.proposalList),function(item,index){return _c('sidebar-item',{key:index,attrs:{"link":{
            name: item.name,
            path: '/provider/' + item.proposal_id
          }}})}),1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Indexers',
          icon: 'ni ni-chart-pie-35 text-info',
          path: '/indexers'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Delegators',
          icon: 'ni ni-map-big text-primary',
          path: '/delegators'
        }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('div',{staticClass:"fixed-bottom"},[_c('div',{staticClass:"dropdown"},[(!_vm.account)?_c('button',{staticClass:"border-btn",on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.$t("Login"))+" ")]):_vm._e(),(_vm.account)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.accountDropdownShow),expression:"accountDropdownShow"}],class:['border-btn-logout'],on:{"click":_vm.logoutAction}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Logout")])]):_vm._e(),(_vm.account)?_c('a',{class:['border-btn', { open: _vm.accountDropdownShow }],on:{"click":function($event){_vm.accountDropdownShow = !_vm.accountDropdownShow}}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.webUtil.formatStrByDot(_vm.account.address)))]),_c('img',{staticStyle:{"color":"white"},attrs:{"src":require("@/assets/img/icon/downward_white.png"),"width":"16"}})]):_vm._e()])])])],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }