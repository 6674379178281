module.exports = {
  lang: "Pусский",
  Home: "Главная",
  Index: "Индекс (PPI)",
  Rank: "Рейтинг",
  SubmitProject: "Добавить Проект",
  All: "Все",
  Login: "Войти",
  Logout: "Выйти",
  Details: "Подробнее",
  NoInformation: "Нет информации",
  ViewAll: "Смотреть все",
  Project: "Проект",
  DevelopmentActivity: "Активность <br> Разработчиков",
  ActivityCurve: "Кривая <br> Активности",
  Token: "Токен",
  PriceChange: "Цена/24ч <br> Изменение",
  Last7Days: "Последние 7 Дней",
  UnderlyingTokens:"Базовые Токены",
  QuantityPerSet:"Количество в корзине",
  TokenPrice:"Цена токена",
  CurrentPriceAllocation:"Текущая <br> Цена Корзины",
  PercentChange:"Изменение в процентах",
  TotalPricePerSet: "Итоговая Стоимость <br> в Корзине",
  About: "About",
  IndexAboutInfo: "PolkaProject индекс (PPI) - это взешенный по капитализации индекс отслеживающий эффективность децентрализованных финансовых активов в целом по рынку.",
  Back: "Назад",
  Voters: "Проголосовало",
  event: "событие",
  VisitWebsite: "Перейти на сайт",
  Share: "Поделиться",
  ParentChain: "Основной чейн",
  SubProjects: "Субпроект",
  SubscribeToUpdates: "Подписаться на Обновления",
  YourEmail: "Ваш email",
  Subscribe: "Подписаться",
  SubmitThisProject: "Добавить обновление по этому проекту",
  projectAboutInfo:"Информация на этом сайте представлена исключительно в образовательных целях и должна использоваться только для ваших личных исследованиях. Мы не даем рекомендаций по инвестициям, не поддерживаем определенный проект или подход, и не обещаем вам какой-либо результат.",
  D:"1Д",
  W:"1Н",
  M:"1М",
  Y:"1Г",
  Search:"Поиск",
  NoResultsFound: "Не найдены результаты для w%",
  NoResultsInfo: "Подсказка: <br>Пожалуйста, убедитесь, что все слова написаны правильно. <br> Пожалуйста, попробуйте другое ключевое слово. <br> Пожалуйста, попробуйте расширить диапазон словосочетаний.",
  NoMore: "No More",
  Title:"Заголовок",
  ProjectTitle: "Заголовок проекта",
  Category:"Категория",
  SelectCategory: "Выберите Категорию",
  ShortIntroduction:"Краткое Введение",
  ShortIntroductionLimit: "Краткое Введение (Ограничено 256 символами)",
  DetailDescription: "Подробное описание",
  Website: "Вебсайт",
  Email: "Email",
  PLOTokenAllocationPlan:"Ссылка на официальный анонс Плана по распределению токенов на PLO (предложение по аренде слота парачейна)",
  Submit: "Добавить",
  EnterTitle:"Пожалуйста, заполните заголовок",
  EnterCategory:"Пожалуйста, выберите категорию",
  EnterShortIntroduction:"Пожалуйста, заполните краткое вступление",
  EnterIntroductionLimited:"Вступление ограничено 256 символами",
  SubmitSuccess:"Успешно добавлено",
  ERROR:"ОШИБКА",
  noMathExtension: "Пожалуйста, сначала подключите Math Wallet",
  noAddress: "Пожалуйста, перейдите для операции в кошелек Polkadot",
  SelectAccount: "Выберите Аккаунт",
  noAccount: "Аккаунт не найден",
  LogInMathWallet: "Войти через расширение MathWallet",
  LogInPolkadotJS: "Войти через расширение PolkadotJS",
  SelectLoginAccount: "Пожалуйста, выберите кошелек, чтобы войти",
  CopySuccess: "Скопированоа успешно",
  CopyFail: "Ошибка при копировании",
  LatestProjects: "Latest Projects",
  AuctionCrowdloan: "Auction & Crowdloan",
  Join: "Join",
};
